exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-impact-tsx": () => import("./../../../src/pages/impact.tsx" /* webpackChunkName: "component---src-pages-impact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partner-tsx": () => import("./../../../src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

